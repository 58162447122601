.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
  background-color: #282c34;
}

.password-input {
  background-color: rgb(255, 255, 255);
  font-size: 150%;
  border: none;
  text-align: center;
  border-radius: 4px;
}

.build-page-output {
  font-family: monospace;
  overflow: scroll;
}

div.article-holder {
  margin-top: 8vh;
  /* max-width: 90%; */
}

.article {
  max-width: calc(min(900px,95%));
  margin-right: auto;
  margin-left: auto;
}

section.article {
  margin-top: 15vh;
}

figcaption.article {
  color:darkgray;
  font-size: 80%;
}

p.article {
  font-size: 95%;
}

img.article {
  max-width: calc(min(800px, 95%));
}


.normal-text-size {
  font-size: 80%;
}

.normal-text-flow {
  text-align: left;
}

input.input-is-ready {
  background-color: rgb(4, 180, 4);
}

.content-all {
  background-color: #282c34;
  min-height: 20vh;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header {
  background-color: #282c34;
  min-height: 20vh;
  max-height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
